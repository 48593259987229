<template>
  <div class="form-data">
    <div
      class="min-h-62 d-md-flex align-items-start gap-2 justify-content-between"
    >
      <div class="col-md-9 bg-white py-2">
        <div class="p-0 mb-">
          <div class="d-md-flex align-items-end justify-content-between">
            <b-form-group
              class="p-0 mb-2 col-md-5"
              label="Selecione o template"
            >
              <multiselect
                required
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Selecione o template"
                v-model="$v.form.template.$model"
                :options="templateOptions"
                @select="handleSelectTemplate"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <span>{{ option.name }} </span>
                </template>
                <template slot="option" slot-scope="props">
                  <span>{{ props.option.name }} </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-alert
              show
              variant="info"
              class="d-flex align-items-center mb-0 p-2"
            >
              Configure um novo a partir deste
              <b-button
                variant="info"
                size="sm"
                class="ml-2"
                @click="handleCopyTemplate()"
              >
                clicando aqui
              </b-button>
            </b-alert>
          </div>
          <div
            class="
              d-md-flex
              align-items-center
              justify-content-start
              w-100
              mt-2
            "
          >
            <b-button
              :variant="selectedModule.name === mod.name ? 'primary' : 'light'"
              size="sm"
              v-for="(mod, index) in modules"
              :key="index"
              @click="handleSelectModule(mod)"
              class="text-md-center d-md-flex align-items-md-center px-2 mr-2"
              :disabled="!mod.enabled"
            >
              {{ mod.name }}
            </b-button>
          </div>
        </div>
        <div class="d-flex">
          <Modules
            class="p-0"
            :template_id="form.template.id"
            :selected_module="selectedModule"
            v-if="form.template && form.template.id && selectedModule"
          />
        </div>
      </div>
      <Premises />
    </div>
    <div class="bottom-navigation-service">
      <b-button
        variant="light"
        :to="
          `/homologation/${this.homologationId}/service/${this.serviceId}/edit/details/`
        "
      >
        <span>Voltar</span>
      </b-button>
      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          :disabled="loadingSubmit"
          :to="
            `/homologation/${this.homologationId}/service/${this.serviceId}/edit/questionaire-service/`
          "
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Avançar para questionário</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import Modules from "./Modules";
import Premises from "./Premises";

export default {
  mixins: [validationMixin],
  components: {
    Modules,
    Premises,
  },
  data() {
    return {
      serviceId: "",
      selectedModule: "",
      loadingSubmit: false,
      homologationId: false,
      templateOptions: [],
      modules: [],
      form: {
        template: {},
      },
    };
  },
  validations: {
    form: {
      template: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "get_modules",
      "get_service_scope",
      "show_toast",
      "copy_template",
      "get_all_templates",
      "edit_service_scope",
    ]),

    afterSubmit() {
      this.$router.push(
        `/homologation/${this.homologationId}/service/${this.serviceId}/edit/invites/`
      );
    },
    handleCopyTemplate() {
      this.copy_template(this.form.template.id).then(({ status, data }) => {
        if (status === 200) {
          this.$router.push(`/system/template-indicators/${data.id}/modules`);
        }
      });
    },
    handleSelectModule(module) {
      this.selectedModule = module;
    },
    handleSelectTemplate(template) {
      this.template = template;
      this.edit_service_scope({ template: template.id, id: this.serviceId });
    },

    getTemplates() {
      this.get_all_templates().then(({ results }) => {
        this.templateOptions = results;
      });
    },
    getModules() {
      this.get_modules(this.serviceId).then(({ results }) => {
        this.modules = results;
        this.handleSelectModule(this.modules[0]);
      });
    },

    getEdit() {
      this.get_service_scope({ id: this.serviceId }).then((data) => {
        this.form = data;
        this.getModules();
      });
    },
  },
  mounted() {
    this.homologationId = this.$route.params.homologation_id;
    this.serviceId = this.$route.params.service_id;

    if (this.serviceId) {
      this.getEdit();
    }
    this.getTemplates();
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>
